import Siema from 'siema'

/**
 * Carousel
 *
 * @param {Element} where
 */
export const carousel = (where) => {
	const carousels = where.querySelectorAll('[data-carousel]')
	Array.from(carousels).forEach(carousel => init(carousel))
}

/**
 * @param {Element} element
 */
const init = element => {
	const carouselItems = element.querySelector('[data-carousel-items]')

	const optionsAttribute = element.getAttribute('data-carousel')
	let options = optionsAttribute ? JSON.parse(optionsAttribute) : {}

	const items = element.querySelectorAll('[data-carousel-item]')
	items.forEach((item, index) => {
		item.setAttribute('data-carousel-item', index)
	})

	const instance = new Siema(Object.assign(options, {
		selector: carouselItems,
		onInit() {
			element.classList.add('js-carousel')
			if (items) {
				setEdges(this, items)
			}
		},
		onChange() {
			if (items) {
				setEdges(this, items)
			}
		}
	}))
	element.querySelectorAll('[data-carousel-prev]')
		.forEach(button => button.addEventListener('click', () => instance.prev()))
	element.querySelectorAll('[data-carousel-next]')
		.forEach(button => button.addEventListener('click', () => instance.next()))
	element.querySelectorAll('[data-carousel-to-start]')
		.forEach(button => button.addEventListener('click', () => instance.goTo(0)))

	if (options.autoplay) {
		if (isNaN(options.autoplay)) {
			console.error('autoplay option must be number of milliseconds')
			return
		}
		const createInterval = () => setInterval(() => {
			instance.next()
		}, options.autoplay)
		let interval = createInterval()
		const pause = () => clearInterval(interval)
		const resume = () => interval = createInterval()
		// hover mouse to pause it
		carouselItems.addEventListener('mouseover', pause)
		carouselItems.addEventListener('touchstart', pause)
		// hold a finger on touch screen to pause it, like in Instagram stories
		carouselItems.addEventListener('mouseout', resume)
		carouselItems.addEventListener('touchend', resume)
	}

	window.addEventListener('resize', () => {
		setEdges(instance, items)
	}, { passive: true })
}

/**
 * @param {{
 *     perPage: int
 *     currentSlide: int
 * }} instance
 * @param {NodeList} items
 */
const setEdges = (instance, items) => {
	const { perPage, currentSlide } = instance
	edges({ perPage, currentSlide, items })
}

/**
 *
 * @param {int}, perPage
 * @param {int} currentSlide
 * @param {NodeList} items
 */
const edges = ({ perPage, currentSlide, items }) => {
	const itemsArray = Array.from(items)
	items.forEach(item => {
		item.removeAttribute('data-carousel-edge')
	})
	if (perPage < 3) return
	const edgeItems = {
		start: currentSlide,
		// FIXME incorrect for loop duplicates
		end: currentSlide + perPage - 1
	}
	const startItem = itemsArray[edgeItems.start]
	const endItem = itemsArray[edgeItems.end]
	startItem.setAttribute('data-carousel-edge', 'start')
	endItem.setAttribute('data-carousel-edge', 'end')
}
